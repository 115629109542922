import { Button, Typography } from 'antd';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import EmptyLayout from '../../components/layout/EmptyLayout';
import SearchEngineOptimization from '../../components/utility/seo';

const { Text, Title } = Typography;

const ForgotPasswordInstructionSent = () => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  return (
    <EmptyLayout>
      <SearchEngineOptimization title="forgot password" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../images/circle-check.png"
          alt="x-circle"
          placeholder="tracedSVG"
          className="w-[48px] h-[48px]"
          objectFit="contain"
        />
        <Title className="!font-semibold mt-5 mb-4 !text-light-title" level={3}>
          ส่งอีเมลเพื่อรีเซ็ตรหัสผ่านแล้ว
        </Title>
        <Text className="!text-light-primary text-center">
          ระบบได้รับคำขอในการรีเซ็ตรหัสผ่านของคุณแล้ว
          คุณจะได้รับอีเมลเพื่อทำตามขั้นตอนต่อไปใน Inbox ภายใน 5
          นาทีหากบัญชีที่ระบุมาตรงกับบัญชีที่อยู่ในระบบเรา
        </Text>
        <Link to={`/`}>
          <Button type="primary" className="mt-6">
            กลับสู่หน้าแรก
          </Button>
        </Link>
      </div>
    </EmptyLayout>
  );
};

export default ForgotPasswordInstructionSent;
